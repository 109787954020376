/* Loading Spinner Animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  border: 20px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 20px solid #5ea545;
  width: 200px;
  height: 200px;
  animation: spin 1s linear infinite;
  margin-right: 0px; /* Adjust spacing as needed */
}
